import { Component } from '@angular/core';
import { FieldComponent } from '../field/field.component';
import { FormsModule } from '@angular/forms';
import { SvgIconDirective } from '@bs24/core/directives/svg-icon.directive';
import { SettingsService } from '@bs24/core/services/settings.service';

@Component({
  selector: 'theme-switcher',
  templateUrl: './theme-switcher.component.html',
  standalone: true,
  imports: [
    FormsModule,
    SvgIconDirective,
    FieldComponent
  ]
})
export class ThemeSwitcherComponent {

  isDarkTheme = false;

  constructor(private appSettingsService: SettingsService) {
    this.isDarkTheme = appSettingsService.settings().darkTheme;
  }

  modelChange(isDark: boolean) {
    this.appSettingsService.saveSetting({darkTheme: isDark});
  }
}
