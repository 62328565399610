<field [input]="{name: 'theme-switcher',type: 'slide-toggle'}" (ngModelChange)="modelChange($event)" [ngModel]="isDarkTheme">
  <div class="pre left-side-toggle me-50">
    <svg class="fill-current" [size]="{w: 18, h: 18}" icon="sun" lib="solid"></svg>
    <label class="toggle-text"></label>
  </div>
  <div class="post right-side-toggle">
    <svg class="fill-current" [size]="{w: 18, h: 18}" icon="moon" lib="solid"></svg>
    <label class="toggle-text"></label>
  </div>
</field>
